import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import NavBarBottom from "./NavBarBottom";
import NavBarTop from "./NavBarTop";
import reportWebVitals from "./reportWebVitals";
import FooterBox from "./FooterBox"

ReactDOM.render(
  <React.StrictMode>
      {/*<NavBarTop/>*/}
      {/*<App />*/}
      {/*<FooterBox/>*/}
      {/*<NavBarBottom/>*/}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
